import React, { useState } from 'react';
import { Box, List, ListItem, Collapse, ListItemIcon, ListItemText, CssBaseline, Drawer, Toolbar, ListItemButton } from '@mui/material/';

import { useNavigate, Outlet, useLocation } from "react-router-dom";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import OutboxIcon from '@mui/icons-material/Outbox';
import LogoutIcon from '@mui/icons-material/Logout';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BedroomParentIcon from '@mui/icons-material/BedroomParent';
import ConstructionIcon from '@mui/icons-material/Construction';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import FactCheckIcon from '@mui/icons-material/FactCheck';

import './Navbar.css';

export default function Navbar() {
  const regex = /(?:.(?!\/))+$/gm;

  let currLocation = '';
  const str = useLocation();
  let m;

  const updateCurrLocation = (match, groupIndex) => {
    currLocation = match;
  };

  while ((m = regex.exec(str.pathname)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    // The result can be accessed through the `m`-variable.
    m.forEach(updateCurrLocation);
    currLocation = currLocation.substring(1);
  }

  const [openState, setOpenState] = useState({
    Pisos: false,
    Auditorias: false,
    Maquinas: false,
    Partes: true,
    // Agrega otros elementos según sea necesario
  });

  const toggleOpen = (element) => () => {
    setOpenState((prevState) => ({
      ...prevState,
      [element]: !prevState[element],
    }));
  };
  let userName = localStorage.getItem('nombreUsuario');
  let departmentId = localStorage.getItem('id_departamento');
  let userId = localStorage.getItem('id');

  const drawerWidth = 160;
  const navigate = useNavigate();
  const cerrarSesion = () => {
    //Recorremos los elementos guardados en el LocalStorage.
    localStorage.clear();
    window.location.reload();
  }

  let listItems = localStorage.getItem('secciones');
  let arr = listItems.split(',');
  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              background: '#002735'
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar >
            <img id="logo" className='clicable' src="/logo.svg" alt="logo" onClick={() => { navigate('/' + 'Home', { replace: true }); }} />

          </Toolbar>
          <p className='textoUsuario sinMargen clicable' onClick={() => { navigate('/' + 'Home', { replace: true }); }}>{userName}</p>
          <br></br>
          <List className='quitarPadding'>
            {
              arr.map((element, index) => {
                //Condicional para comprobar si es Partes. En tal caso imprimir dos botones 1 salida y 1 entrada
                if (element === "Partes") {
                  return (
                    <React.Fragment key={`fragment-${element}`}>
                      <ListItem key={"list-item" + element} id={"Partes"} className='quitarPadding colorSeleccion'>
                        <ListItemButton key={"list-item-button-" + element} onClick={toggleOpen(element)}>
                          <ListItemText className='navbar-text-padre'>Partes</ListItemText>
                          {openState[element] ? <ExpandLess className='icono' /> : <ExpandMore className='icono' />}
                        </ListItemButton>
                      </ListItem>
                      <Collapse key={"collapse" + element} in={openState[element]} timeout="auto" unmountOnExit className='fondoCollapse'>
                        <ListItem key={"list-item-Entradas"} className={currLocation === 'Entradas' ? 'currLocation quitarPadding menuHijo' : 'quitarPadding colorSeleccion menuHijo'} id={"Entradas"} onClick={() => { navigate('/' + element + '/Entradas', { replace: true }); }}>
                          <ListItemButton key={"list-item-button-Entradas"}  >
                            <ListItemIcon className="icon">
                              <InboxIcon className="icono" />
                            </ListItemIcon>
                            <ListItemText className='navbar-text menuHijoTxt'>Entradas</ListItemText>
                          </ListItemButton>
                        </ListItem>
                        <ListItem key={"list-item-Salidas"} className={currLocation === 'Salidas' ? 'currLocation quitarPadding menuHijo' : 'quitarPadding colorSeleccion menuHijo'} id={"Salidas"} onClick={() => { navigate('/' + element + '/Salidas', { replace: true }); }}>
                          <ListItemButton key={"list-item-button-Salidas"}  >
                            <ListItemIcon className="icon">
                              <OutboxIcon className="icono" />
                            </ListItemIcon>
                            <ListItemText className='navbar-text menuHijoTxt'>Salidas</ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </Collapse>
                    </React.Fragment>
                  )
                }
                else if (element === "Maquinas") {
                  return (
                    <React.Fragment key={`fragment-${element}`}>
                      <ListItem key={"list-item" + element} id={"Maquinas"} className='quitarPadding colorSeleccion'>
                        <ListItemButton key={"list-item-button-" + element} onClick={toggleOpen(element)}>
                          <ListItemText className='navbar-text-padre'>Maquinas</ListItemText>
                          {openState[element] ? <ExpandLess className='icono' /> : <ExpandMore className='icono' />}
                        </ListItemButton>
                      </ListItem>
                      <Collapse key={"collapse" + element} in={openState[element]} timeout="auto" unmountOnExit className='fondoCollapse'>
                        <ListItem key={"list-item-Maquinaria"} className={currLocation === 'Maquinas' ? 'currLocation quitarPadding menuHijo' : 'quitarPadding colorSeleccion menuHijo'} id={"Maquinas"} onClick={() => { navigate('/Maquinas', { replace: true }); }}>
                          <ListItemButton key={"list-item-button-Maquinaria"}  >
                            <ListItemIcon className="icon">
                              <InboxIcon className="icono" />
                            </ListItemIcon>
                            <ListItemText className='navbar-text menuHijoTxt'>Maquinas</ListItemText>
                          </ListItemButton>
                        </ListItem>
                        <ListItem key={"list-item-Lecturas"} className={currLocation === 'Lecturas' ? 'currLocation quitarPadding menuHijo' : 'quitarPadding colorSeleccion menuHijo'} id={"Lecturas"} onClick={() => { navigate('/Lecturas', { replace: true }); }}>
                          <ListItemButton key={"list-item-button-Lecturas"}  >
                            <ListItemIcon className="icon">
                              <FactCheckIcon className="icono" />
                            </ListItemIcon>
                            <ListItemText className='navbar-text menuHijoTxt'>Lecturas</ListItemText>
                          </ListItemButton>
                        </ListItem>
                        {/* Condicional para mostrar "Conf Maquinas" */}
                        {arr.includes("conf-maquinas") && (
                          <ListItem key={"list-item-ConfMaquinas"} className={currLocation === 'ConfMaquinas' ? 'currLocation quitarPadding menuHijo' : 'quitarPadding colorSeleccion menuHijo'} id={"ConfMaquinas"} onClick={() => { navigate('/ConfMaquinas', { replace: true }); }}>
                            <ListItemButton key={"list-item-button-ConfMaquinas"}>
                              <ListItemIcon className="icon">
                                <SettingsIcon className="icono" />
                              </ListItemIcon>
                              <ListItemText className='navbar-text menuHijoTxt'>Configuración</ListItemText>
                            </ListItemButton>
                          </ListItem>
                        )}
                      </Collapse>
                    </React.Fragment>
                  )
                } else {//En el caso de no ser un Parte, añadarimos la seccion a la lista de secciones.
                  if (element === "Pisos" || userId == 491 || userId == 364 || userId == 91 || userId == 30) { // departmentId == 71 //userId == 91 Carlos
                    if (element !== "Lecturas" && element !== "conf-maquinas") {

                      return (
                        <React.Fragment key={`fragment-${element}`}>
                          <ListItem key={"list-item" + element} id={element + "-menu"} className='quitarPadding colorSeleccion'>
                            <ListItemButton key={"list-item-button-" + element} onClick={toggleOpen(element)}>
                              <ListItemText className='navbar-text-padre'>{element == 'Pisos' ? 'Habitaciones' : element == 'SubirYConsultar' ? 'Documentos' : element}</ListItemText>
                              {openState[element] ? <ExpandLess className='icono' /> : <ExpandMore className='icono' />}
                            </ListItemButton>
                          </ListItem>
                          <Collapse key={"collapse" + element} in={openState[element]} timeout="auto" unmountOnExit className='fondoCollapse'>
                            <ListItem

                              className={currLocation === element ? 'currLocation quitarPadding' : 'quitarPadding colorSeleccion'}
                              id={element + "-item"}
                              onClick={() => {
                                navigate('/' + element, { replace: true });
                              }}
                            >
                              <ListItemButton key={"list-item-button-" + element} >
                                <ListItemIcon className="icon">
                                  {element === "Auditorias" ? (
                                    <AssignmentTurnedInIcon className="icono" />
                                  ) : element === "Pisos" ? (
                                    <BedroomParentIcon className="icono" />
                                  ) : element === "Usuarios" ? (
                                    <PeopleIcon className="icono" />
                                  ) : (
                                    <ConstructionIcon className="icono" />
                                  )}
                                </ListItemIcon>
                                <ListItemText className='navbar-text'>{element === "Pisos" ? "Mapa" : element === 'SubirYConsultar' ? 'Subir/Consultar' : element}</ListItemText>
                              </ListItemButton>
                            </ListItem>
                          </Collapse>
                        </React.Fragment>
                      );
                    }

                  }
                }
              })
            }
          </List>
          <List className='navbar-bottom ' >
            <ListItem key={"list-item-Manual"} id={"Manual"} className='quitarPadding colorSeleccion manualWorkers'>
              <ListItemButton key={"list-item-button-Manual"} onClick={() => { window.open('https://api.workers.grupotel.com/documentos/manuales/Manual%20Workers%202.0.pdf'); }}>
                <ListItemText className='textoManual'>Manual Workers</ListItemText>
              </ListItemButton>
            </ListItem>

            <ListItem key={"list-item-bottom"} className='quitarPadding'>
              <ListItemButton key={"list-item-button-Ajustes"} className='logout' onClick={() => { navigate('/' + 'Ajustes', { replace: true }); }}>
                <ListItemIcon className="icon">
                  <SettingsIcon className="icono" />
                </ListItemIcon>
              </ListItemButton>
              <ListItemButton key={"list-item-button-logout"} className='logout' onClick={cerrarSesion} >
                <ListItemIcon className="icon">
                  <LogoutIcon className="cerrarSesion" />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
        <Box
          className='container'
          component="main"
          sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
        >
          <Outlet />
        </Box>
      </Box>
    </div>
  );
}