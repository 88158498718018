import React, { useState, useEffect } from 'react';
import { Typography, Button, MenuItem, TextField, IconButton, ListSubheader, List, ListItemButton, ListItemText, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import MostrarError from '../../components/AccionesPartes/MostrarError';
import { Link } from 'react-router-dom';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import './SubirYConsultar.css';
import dayjs from 'dayjs';

function SubirYConsultar() {
    // Create a new Date object
    var today = new Date();

    // Get the current date components
    var year = today.getFullYear();
    var month = today.getMonth() + 1; // Note: Month is zero-based, so we add 1
    var day = today.getDate();

    // Format the date as a string (optional)
    var today = year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;


    const [mensajeError, setMensajeError] = useState('');
    const [tipoError, setTipoError] = useState('');
    const [contadorError, setContadorError] = useState(1);
    const [estadoAlert, setEstadoAlert] = React.useState(false);

    //Variable que contendra el objeto PDF
    const [ubicacionPdf, setUbicacionPdf] = useState('');
    //Contiene unicamente el nombre
    const [nombrePdf, setNombrePdf] = useState('');

    let hoteles = localStorage.getItem('hoteles');

    const [hotelesLista, SetHotelesLista] = useState([]);

    const [hotelTrabajo, SetHotelTrabajo] = useState(0);
    const [tipoSelect, SetTipoSelect] = useState(0);
    const [documentoSelect, SetDocumentoSelect] = useState([]);
    const [referenciaSelect, SetReferenciaSelect] = useState([]);

    const [estadoInicial, setEstadoInicial] = React.useState(true);

    const [contadorSpinner, setContadorSpinner] = React.useState(false);
    const [FDoc, setFDoc] = React.useState(today);
    const [FProx, setFProx] = React.useState();
    const [tipos, setTipos] = React.useState('');
    const [observaciones, setObservaciones] = React.useState('');
    const [referencias, setReferencias] = React.useState('');

    const [maquinas, setMaquinas] = React.useState('');
    const [maquinasFiltro, setMaquinasFiltro] = useState([...maquinas]);
    const [filtroGrupo, setFiltroGrupo] = useState(null);
    const [filtroHotel, setFiltroHotel] = useState(null);

    const [grupos, setGrupos] = React.useState('');
    const [documentos, setDocumentos] = React.useState('');
    const [maquinasGrupos, setMaquinasGrupos] = React.useState('');
    const [vehiculos, setVehiculos] = React.useState('');
    const [electricas, setElectrica] = React.useState('');
    const [residuos, setResiduos] = React.useState('');
    const [transformadores, setTransformadores] = React.useState('');
    const [tiposMaestro, setTiposMaestro] = React.useState('');
    const [aguas, setAguas] = React.useState('');
    const [combustibles, setCombustibles] = React.useState('');
    const [protecciones, setProteccion] = React.useState('');

    const [documentosLista, setDocumentosLista] = React.useState([]);

    const cambiaHotelTrabajo = (event) => {
        if (event.target.name === 'id_hotel') {
            SetHotelTrabajo(event.target.value);
            cambiarFiltroHotel(event.target.value);
        }
    }
    const cambiaTipoSelect = (event) => {
        if (event.target.name === 'tipoSelect') {
            // console.log(event.target.value)
            SetTipoSelect(event.target.value);
        }
    }
    const cambiaDocumentoSelect = (event) => {
        if (event.target.name === 'documentoSelect') {
            // console.log(event.target.value)
            SetDocumentoSelect(event.target.value);
            SetReferenciaSelect([])
            cambiarFiltroGrupo(event.target.value.id_referencia_grupo);
        }
    }
    const cambiaReferencia = (event) => {
        console.log(event)
        if (event.target.name === 'referenciaSelect') {
            // console.log(event.target.value)
            SetReferenciaSelect(event.target.value);
        }
    }

    const mostrarError = (mensajeError, tipoError) => {
        setMensajeError(mensajeError);
        if (tipoError) {
            setTipoError(tipoError);
        } else {
            setTipoError("error");
        }
        setContadorError(contadorError + 1);
        setEstadoAlert(true);
        const timer2 = setTimeout(() => {
            setEstadoAlert(false);
        }, 3000);
        return () => clearTimeout(timer2);
    };
    const selecPdf = (event) => {
        if (event.target.files[0] !== undefined) {
            setUbicacionPdf(event.target.files[0]);
            setNombrePdf(event.target.files[0].name);
        } else {
            setUbicacionPdf("");
            setNombrePdf("");
        }
    };
    const borrarPdf = () => {
        //Vaciar el contenido del input de PDF.
        document.querySelector("#upload-pdf").value = "";
        setUbicacionPdf("");
        setNombrePdf("");
    }
    const ventanaPdf = (event) => {
        //Abrimos la imagen en una nueva ventana.
        window.open(URL.createObjectURL(ubicacionPdf));
    };
    // Función para aplicar los filtros y actualizar maquinasFiltro
    const aplicarFiltros = () => {
        let maquinasFiltradas = [...maquinas];

        if (filtroGrupo !== null) {
            maquinasFiltradas = maquinasFiltradas.filter(maquina => maquina.id_grupo === filtroGrupo);
        }

        if (filtroHotel !== null) {
            maquinasFiltradas = maquinasFiltradas.filter(maquina => maquina.id_hotel === filtroHotel);
        }

        setMaquinasFiltro(maquinasFiltradas);
    };

    // Función para cambiar el filtro por id_grupo
    const cambiarFiltroGrupo = (idGrupo) => {
        setFiltroGrupo(idGrupo);
    };

    // Función para cambiar el filtro por id_hotel
    const cambiarFiltroHotel = (idHotel) => {
        setFiltroHotel(idHotel);
        getInfoDocumentos(idHotel);
    };
    const cambiaObservaciones = (event) => {
        if (event.target.name === 'observaciones') {
            // console.log(event.target.value)
            setObservaciones(event.target.value);
        }
    }


    // Lo siento, se puede acortar, pero quiero acabar esto.. -CGB
    const compruebaInputs = () => {
        if (tipoSelect) {
            if (documentoSelect) {
                if (FProx) {
                    if (nombrePdf) {
                        return true;
                    } else {
                        mostrarError('Documento no seleccionado.')
                        return false
                    }
                } else {
                    mostrarError('Fecha próxima no seleccionada.')
                }
            } else {
                mostrarError('Documento no seleccionado.')
                return false
            }
        } else {
            mostrarError('Tipo de documento no seleccionado.')
            return false
        }
    } //Lo siento de veras...

    // Función para registrar el formulario.

    const registrar = () => {
        if (compruebaInputs()) {
            const fd = new FormData();
            const requestOptions = {
                method: 'POST',
                body: fd
            };
            console.log(referenciaSelect)
            fd.append("f", "documentos.setNewDocumento");
            fd.append("token", localStorage.getItem('token'));
            fd.append("id_hotel", hotelTrabajo);
            fd.append("tipo", tipoSelect);
            fd.append("documento", documentoSelect.id ? documentoSelect.id : documentoSelect.matricula);
            fd.append("grupo", documentoSelect.referencia);
            fd.append("referencia", referenciaSelect.id ? referenciaSelect.id : referenciaSelect.matricula ? referenciaSelect.matricula : '');
            fd.append("FDoc", FDoc);
            fd.append("FProx", FProx);
            fd.append("observaciones", observaciones);
            fd.append("archivo", ubicacionPdf);

            fetch('https://api.workers.grupotel.com/', requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    let error = result.error;

                    if (error.id === 0) {
                        getDocumentosAll();

                    } else {
                        mostrarError(error.msg)
                    }
                })
        }
    }

    // Funcion para obtener documentos.
    const getDocumentosAll = () => {
        const fd = new FormData();
        const requestOptions = {
            method: 'POST',
            body: fd
        };
        //setDepartamentosLista('');
        fd.append("f", "documentos.getDocumentosAll");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id_hotel", hotelTrabajo);

        fetch('https://api.workers.grupotel.com/', requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                let error = result.error;
                setDocumentosLista(result.documentos)
                if (error.id === 0) {

                } else {
                    mostrarError(error.msg)
                }
            })
    }

    const goToFile = (documento => {
        console.log(documento)
        var url = 'https://api.workers.grupotel.com/documentos/';
        var id_hotel = documento.id_hotel;
        var id_documento = documento.id_documento;
        var id_referencia = documento.id_referencia;
        var id_referencia_grupo = documento.id_referencia_grupo;
        var fichero = documento.fichero;

        url = url + id_hotel + '/Requisitos Legales/' + id_referencia + '/' + fichero;
        window.open(url, '_blank');
    })

    const getInfoDocumentos = (id_hotel = 0) => {

        let listaHoteles = JSON.parse(hoteles);
        SetHotelesLista(listaHoteles);

        //Si tenemos un hotel guardado en el localStorage utilizando Ajustes.
        let idHotel = localStorage.getItem('idHotelSeleccionado');
        console.log(listaHoteles);
        if (estadoInicial) {
            if (idHotel) {
                SetHotelTrabajo(idHotel);
                setEstadoInicial(false);
                id_hotel = idHotel
            } else {
                console.log(listaHoteles);
            }
        }
        const fd = new FormData();
        const requestOptions = {
            method: 'POST',
            body: fd
        };
        //setDepartamentosLista('');
        fd.append("f", "documentos.getInfoDocumentos");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id_hotel", id_hotel);
        console.log("hotelTrabajo: ", id_hotel)

        fetch('https://api.workers.grupotel.com/', requestOptions)
            .then(response => response.json())
            .then(result => {
                let error = result.error;

                if (error.id === 0) {
                    delete result.error;
                    console.log(result)
                    setTipos(result.tipo)
                    setGrupos(result.grupo)
                    setDocumentos(result.documentos)
                    setReferencias(result.referencia)
                    setMaquinasGrupos(result.maquinas_grupos)
                    setMaquinas(result.maquinas)
                    setVehiculos(result.vehiculos)
                    setElectrica(result.electrica)
                    setResiduos(result.residuos)
                    setTransformadores(result.transformadores)
                    setTiposMaestro(result.tipos_maestro)
                    setAguas(result.aguas)
                    setCombustibles(result.combustibles)
                    setProteccion(result.proteccion)



                    const timer2 = setTimeout(() => {
                        setContadorSpinner(false)
                    }, 500);

                } else if (error.id >= 501 && error.id <= 510) {
                    localStorage.setItem('token', 'CADUCADO');
                    window.location.reload();
                } else {
                    mostrarError(error.msg)
                    console.log(error.msg)
                }
            })
            .catch(() => {
                mostrarError("No se ha podido obtener.")
                console.log('No se ha podido obtener.')
            })
    }

    useEffect(() => {
        getInfoDocumentos();
        getDocumentosAll();

    }, []);
    useEffect(() => {
        aplicarFiltros();
    }, [maquinas, filtroGrupo, filtroHotel]);


    return (
        <div>
            <Accordion defaultExpanded className="subiryconsultar">
                <AccordionSummary className="subiryconsultarHeader" id="panel-header" aria-controls="panel-content">
                    <Typography variant="h5" component="div" className='centrar'>
                        Subir fichero
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className='subiryconsultarContainer'>
                    <div className='subiryconsultarRow'>
                        <div className='dycinput '>
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                label="Hotel"
                                name="id_hotel"
                                size="small"
                                value={hotelTrabajo ? hotelTrabajo : ""}
                                onChange={cambiaHotelTrabajo}
                                select
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            sx: { maxHeight: { sm: 36 * 8 + 8 } }
                                        }
                                    }
                                }}
                            >
                                {hotelesLista.length !== 0 ? hotelesLista.map((hotel) => (
                                    <MenuItem key={hotel.value} value={hotel.value} >
                                        {hotel.label}
                                    </MenuItem>
                                )) : ''}
                            </TextField>
                        </div>
                        <div className='dycinput '>
                            <TextField
                                fullWidth
                                defaultValue=""
                                variant="outlined"
                                margin="normal"
                                label="Tipo"
                                name="tipoSelect"
                                size="small"
                                value={tipoSelect ? tipoSelect : ""}
                                onChange={cambiaTipoSelect}
                                select
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            sx: { maxHeight: { sm: 36 * 8 + 8 } }
                                        }
                                    }
                                }}
                            >
                                <MenuItem value={0} key={0}>
                                </MenuItem>
                                {tipos.length !== 0 ? tipos.map((tipo, index) => (
                                    <MenuItem value={tipo} key={index}>
                                        {tipo}
                                    </MenuItem>
                                )) : ''}

                            </TextField>
                        </div>
                        <div className='dycinput '>
                            {hotelTrabajo > 0 ?
                                <TextField
                                    fullWidth
                                    defaultValue=""
                                    variant="outlined"
                                    margin="normal"
                                    label="Documento"
                                    name="documentoSelect"
                                    size="small"
                                    value={documentoSelect ? documentoSelect : ""}
                                    onChange={cambiaDocumentoSelect}
                                    select
                                    SelectProps={{
                                        sx: { width: '100%' },
                                        MenuProps: {
                                            PaperProps: {
                                                sx: { maxHeight: { sm: 36 * 8 + 8 } }
                                            }
                                        }
                                    }}
                                >
                                    {Object.keys(documentos).map((categoria) => (
                                        [
                                            <ListSubheader>{categoria}</ListSubheader>,
                                            ...documentos[categoria].map((documento) => (
                                                <MenuItem key={documento.id} value={documento}>
                                                    {documento.nombre}
                                                </MenuItem>
                                            ))
                                        ]
                                    ))}
                                </TextField>
                                :
                                <TextField
                                    fullWidth
                                    defaultValue=""
                                    variant="outlined"
                                    margin="normal"
                                    label="Documento"
                                    name="documentoSelect"
                                    size="small"
                                    disabled
                                    value={documentoSelect ? documentoSelect : ""}
                                    onChange={cambiaDocumentoSelect}
                                    select
                                    SelectProps={{
                                        sx: { width: '100%' },
                                        MenuProps: {
                                            PaperProps: {
                                                sx: { maxHeight: { sm: 36 * 8 + 8 } }
                                            }
                                        }
                                    }}
                                ></TextField>
                            }

                        </div>
                        <div className='dycinput '>
                            {documentoSelect.length != 0 || documentoSelect != 0 ?
                                documentoSelect.referencia !== 'Hotel' ?
                                    <TextField
                                        defaultValue=""
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        label="Referencia"
                                        name="referenciaSelect"
                                        size="small"
                                        value={referenciaSelect ? referenciaSelect : ""}
                                        onChange={cambiaReferencia}
                                        select
                                        SelectProps={{
                                            MenuProps: {
                                                PaperProps: {
                                                    sx: { maxHeight: { sm: 36 * 8 + 8 } }
                                                }
                                            }
                                        }}
                                    >
                                        {documentoSelect.referencia === 'Maquinaria' &&
                                            maquinasFiltro.map((maquina) => (
                                                <MenuItem value={maquina} key={maquina.id}>{maquina.nombre}</MenuItem>
                                            ))
                                        }
                                        {documentoSelect.referencia === 'Aguas' &&
                                            aguas.map((agua) => (
                                                <MenuItem value={agua} key={agua.id}>{agua.nombre}</MenuItem>
                                            ))
                                        }
                                        {documentoSelect.referencia === 'Instalacion Electrica de Baja Tension' &&
                                            electricas.map((electrica) => (
                                                <MenuItem value={electrica} key={electrica.id}>{electrica.nombre}</MenuItem>
                                            ))
                                        }
                                        {documentoSelect.referencia === 'Residuos' &&
                                            residuos.map((residuo) => (
                                                <MenuItem value={residuo} key={residuo.id}>{residuo.nombre}</MenuItem>
                                            ))
                                        }
                                        {documentoSelect.referencia === 'Combustibles' &&
                                            combustibles.map((combustible) => (
                                                <MenuItem value={combustible} key={combustible.id}>{combustible.nombre}</MenuItem>
                                            ))
                                        }
                                        {documentoSelect.referencia === 'Transformadores' &&
                                            transformadores.map((transformador) => (
                                                <MenuItem value={transformador} key={transformador.id}>{transformador.nombre}</MenuItem>
                                            ))
                                        }
                                        {documentoSelect.referencia === 'Equipacion proteccion contra incendios' &&
                                            protecciones.map((proteccion) => (
                                                <MenuItem value={proteccion} key={proteccion.id}>{proteccion.nombre}</MenuItem>
                                            ))
                                        }
                                        {/* {documentoSelect.referencia === 'Piscinas' &&
                                            piscinas.map((piscina) => (
                                                <MenuItem value={piscina} key={piscina.id}>{piscina.nombre}</MenuItem>
                                            ))
                                        } */}
                                        {documentoSelect.referencia === 'Vehiculos' &&
                                            vehiculos.map((vehiculo) => (
                                                <MenuItem value={vehiculo} key={vehiculo.matricula}>{vehiculo.marca}  {vehiculo.modelo}</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                    :
                                    <TextField
                                        defaultValue=""
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        label="Referencia"
                                        name="referenciaSelect"
                                        size="small"
                                        value={referenciaSelect ? referenciaSelect : ""}
                                        onChange={cambiaReferencia}
                                        select
                                        disabled
                                        SelectProps={{
                                            MenuProps: {
                                                PaperProps: {
                                                    sx: { maxHeight: { sm: 36 * 8 + 8 } }
                                                }
                                            }
                                        }}
                                    ></TextField>
                                :
                                <TextField
                                    defaultValue=""
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    label="Referencia"
                                    name="referenciaSelect"
                                    size="small"
                                    value={referenciaSelect ? referenciaSelect : ""}
                                    onChange={cambiaReferencia}
                                    select
                                    disabled
                                    SelectProps={{
                                        MenuProps: {
                                            PaperProps: {
                                                sx: { maxHeight: { sm: 36 * 8 + 8 } }
                                            }
                                        }
                                    }}
                                ></TextField>
                            }

                        </div>

                    </div>

                    <div className='subiryconsultarRow'>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div className='dycinputfl '>
                                <DatePicker
                                    format="DD-MM-YYYY"
                                    name='FDoc'
                                    onChange={setFDoc}
                                    label="Fecha Documento"
                                    slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                    defaultValue={dayjs()}
                                />
                            </div>
                            <div className='dycinputfl '>
                                <DatePicker
                                    format="DD-MM-YYYY"
                                    name='FProx'
                                    onChange={(setFProx)}
                                    label="Fecha Próxima"
                                    slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                />
                            </div>
                            <div className='dycinput '>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    className='dycobs'
                                    label="Observaciones"
                                    name='observaciones'
                                    value={observaciones}
                                    fullWidth
                                    multiline
                                    rows={3}
                                    onChange={cambiaObservaciones}
                                />
                            </div>
                            <div className='dycbotonera'>
                                <div className='dycbotoneraseparado'>
                                    <div>
                                        <label htmlFor="upload-pdf" className=''>
                                            <input
                                                className='ocultar'
                                                accept=".pdf"
                                                id="upload-pdf"
                                                type="file"
                                                onChange={selecPdf}
                                            />
                                            <Button component='span' className='estiloDivider permiso' variant="contained">Subir Fichero</Button>
                                        </label>
                                        {nombrePdf ?
                                            <div>
                                                <Button className='nombrePdf' variant="text" onClick={ventanaPdf} >{nombrePdf ? nombrePdf : ''}</Button>
                                                <IconButton className='iconX' onClick={borrarPdf} >x</IconButton>
                                            </div>
                                            :
                                            <div>
                                            </div>
                                        }
                                    </div>
                                    {nombrePdf && tipoSelect && documentoSelect && FDoc ?
                                        <div>
                                            <Button onClick={registrar} component='span' className='estiloDivider permiso' variant="contained">Registrar</Button>
                                        </div>
                                        :
                                        <div>

                                            <Button disabled component='span' className='estiloDivider permiso' variant="contained">Registrar</Button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </LocalizationProvider>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion className='subiryconsultar'>
                <AccordionSummary className="subiryconsultarHeader" id="panel-header" aria-controls="panel-content">
                    <Typography variant="h5" component="div" className='centrar'>
                        Ficheros
                    </Typography>
                </AccordionSummary>

                <AccordionDetails className='subiryconsultarContainer'>
                    <div className='subiryconsultarrow'>

                    </div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Hotel</TableCell>
                                <TableCell>Documento</TableCell>
                                <TableCell>Fecha Registro</TableCell>
                                <TableCell>Fecha Documento</TableCell>
                                <TableCell>Fecha Próxima</TableCell>
                                <TableCell>Fichero</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {documentosLista ? documentosLista.map((documento) => {
                                const documentoEncontrado = Object.values(documentos).flat().find(sdoc => sdoc.id === documento.id_documento);
                                const nombreDocumento = documentoEncontrado ? documentoEncontrado.nombre : 'Nombre no encontrado';


                                return (
                                    <TableRow>
                                        <TableCell>{documento.id}</TableCell>
                                        <TableCell>
                                            {hotelesLista.find(hotel => hotel.value === documento.id_hotel)?.label || 'Hotel no encontrado'}
                                        </TableCell>
                                        <TableCell>
                                            {nombreDocumento}
                                        </TableCell>
                                        <TableCell>{documento.fecha_registro}</TableCell>
                                        <TableCell>{documento.fecha_documento}</TableCell>
                                        <TableCell>{documento.fecha_proxima}</TableCell>
                                        <TableCell><Button onClick={() => { goToFile(documento) }} fullWidth component='span' className=' permiso' variant="contained"><ContentPasteSearchIcon></ContentPasteSearchIcon></Button></TableCell>
                                    </TableRow>
                                )
                            }) : ''}
                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>
            <MostrarError mensajeEr={mensajeError} tipoError={tipoError} contadorError={contadorError} estadoAlert={estadoAlert} />
        </div>
    )
}
export default SubirYConsultar;