import React, { useEffect, useState } from 'react'
import { } from '@mui/material';
import MostrarError from '../../../components/AccionesPartes/MostrarError';
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'

import './Usuarios.css';

function Usuarios() {
    const API_URL = 'https://api.workers.grupotel.com/';

    const [mensajeError, setMensajeError] = useState('');
    const [tipoError, setTipoError] = useState('');
    const [contadorError, setContadorError] = useState(1);
    const [estadoAlert, setEstadoAlert] = React.useState(false);

    const [usuarioSelect, setUsuarioSelect] = useState('');
    const [departamentoSelect, setDepartamentoSelect] = useState('');
    const [rolSelect, setRolSelect] = useState('');

    const [usuarios, setUsuarios] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [roles, setRoles] = useState([]);
    const [hoteles, setHoteles] = useState([]);

    const [idsHotelesPoner, setIdsHotelesPoner] = useState([]);
    const [idsHotelesQuitar, setIdsHotelesQuitar] = useState([]);

    const selectUsuario = (event) => {
        if (event.target.name === 'selectUsuario') {
            if (event.target.value == 0) {
                setDepartamentoSelect();
                setRolSelect();
            }
            setUsuarioSelect(event.target.value);
            getUserData(event.target.value);
        }
    }
    const selectDepartamento = (event) => {
        if (event.target.name === 'selectDepartamento') {
            setDepartamentoSelect(event.target.value);
        }
    }
    const selectRol = (event) => {
        if (event.target.name === 'selectRol') {
            setRolSelect(event.target.value);
        }
    }
    const toggleHotel = (ev) => {
        let id_hotel = ev.target.id;
        // Obtén el hotel correspondiente al id_hotel del evento
        // Verificar si el hotel está en idsHotelesQuitar
        if (idsHotelesQuitar.includes(id_hotel)) {
            // El hotel ya está en idsHotelesQuitar, así que lo eliminamos del array
            setIdsHotelesQuitar(idsHotelesQuitar.filter(hotelId => hotelId !== id_hotel));
        } else if (idsHotelesPoner.includes(id_hotel)) {
            // Verificar si el hotel está en idsHotelesPoner
            // El hotel ya está en idsHotelesPoner, así que lo eliminamos del array
            setIdsHotelesPoner(idsHotelesPoner.filter(hotelId => hotelId !== id_hotel));
        } else {
            // Si el hotel no está en ninguno de los dos arrays, entonces aplicamos la lógica original
            const hotelSeleccionado = hoteles.find(hotel => hotel.id === id_hotel);

            if (hotelSeleccionado && hotelSeleccionado.permiso === '1') {
                // El hotel tiene permiso igual a 1, por lo que lo agregamos a idsHotelesQuitar
                setIdsHotelesQuitar([...idsHotelesQuitar, id_hotel]);
            } else {
                // El hotel no tiene permiso igual a 1 o no se encontró en la lista de hoteles, por lo que lo agregamos a idsHotelesPoner
                setIdsHotelesPoner([...idsHotelesPoner, id_hotel]);
            }
        }
    }

    const mostrarError = (mensajeError, tipoError) => {
        setMensajeError(mensajeError);
        if (tipoError) {
            setTipoError(tipoError);
        } else {
            setTipoError("error");
        }
        setContadorError(contadorError + 1);
        setEstadoAlert(true);
        const timer2 = setTimeout(() => {
      setEstadoAlert(false);
    }, 3000);
        return () => clearTimeout(timer2);
    };

    const getUserData = (id = 0) => {
        const fd = new FormData();
        fd.append("f", "hotel.getUserData");
        fd.append("id", id);
        fd.append("token", localStorage.getItem('token'));
        const requestOptions = {
            method: 'POST',
            body: fd
        };

        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.hoteles) {
                    // Convierte el objeto de hoteles en un array de objetos
                    const hotelesArray = Object.keys(result.hoteles).map(key => ({
                        id: key,
                        ...result.hoteles[key]
                    }));

                    // Ordena el array de hoteles por el campo "nombre"
                    hotelesArray.sort((a, b) => a.nombre.localeCompare(b.nombre));

                    // Guarda el array de hoteles ordenados en el estado
                    setHoteles(hotelesArray);
                    setIdsHotelesPoner([])
                    setIdsHotelesQuitar([])
                } else {
                    // Si no hay hoteles, establece el estado como un array vacío
                    setHoteles([]);
                    setIdsHotelesPoner([])
                    setIdsHotelesQuitar([])
                }
                if (result.id_departamento) {
                    setDepartamentoSelect(result.id_departamento)

                } else {
                    setDepartamentoSelect('')
                }
                if (result.id_rol) {
                    setRolSelect(result.id_rol)

                } else {
                    setRolSelect('')
                }

            })
            .catch(() => {
                mostrarError("No se han podido descargar los datos para poblar el formulario.");
            })
    }

    const setUserData = () => {


        let params = '';
        if (idsHotelesPoner.length !== 0) {
            params += "id_hoteles_poner=" + idsHotelesPoner.join(",") + '|';
        }
        if (idsHotelesQuitar.length !== 0) {
            params += "id_hoteles_quitar=" + idsHotelesQuitar.join(",") + '|';
        }
        if (departamentoSelect !== '') {
            params += "id_departamento=" + departamentoSelect + '|';
        }
        if (rolSelect !== '') {
            params += "id_rol=" + rolSelect + '|';
        }
        if (params === '') {
            // mostrarError("No debería verse esto...");
        } else {
            const fd = new FormData();
            fd.append("f", "hotel.setUserData");
            fd.append("id", usuarioSelect);
            fd.append("token", localStorage.getItem('token'));
            // id_hoteles_poner=47,10,20,60|id_hoteles_quitar=47,10,20,60|
            fd.append("params", params)
            const requestOptions = {
                method: 'POST',
                body: fd
            };
            fetch(API_URL, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.error.id === 0) {
                        mostrarError(result.error.msg, "success")
                        getUserData(usuarioSelect)
                    } else {
                        mostrarError(result.error.msg)
                    }
                })
                .catch(() => {
                    mostrarError("Error al guardar el usuario.")
                })
        }

    }

    const getMontaSelect = () => {
        const fd = new FormData();
        fd.append("f", "usuarios.getMontaSelect");
        fd.append("token", localStorage.getItem('token'));
        const requestOptions = {
            method: 'POST',
            body: fd
        };

        fetch(API_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.usuarios) {
                    // Convertir el objeto en un array de pares clave-valor
                    // Convertir el objeto en un array de objetos
                    const usuariosArray = Object.entries(result.usuarios).map(([clave, nombre]) => ({ clave, nombre }));

                    // Ordenar el array de usuarios en función del segundo campo (nombre)
                    usuariosArray.sort((a, b) => a.nombre.localeCompare(b.nombre));

                    setUsuarios(usuariosArray)
                } else {
                    // Si no hay usuarios, establece el estado como un array vacío
                    setUsuarios([]);
                }

                if (result.departamentos) {
                    // Convertir el objeto en un array de pares clave-valor
                    // Convertir el objeto en un array de objetos
                    const departamentosArray = Object.entries(result.departamentos).map(([clave, nombre]) => ({ clave, nombre }));

                    // Ordenar el array de departamentos en función del segundo campo (nombre)
                    departamentosArray.sort((a, b) => a.nombre.localeCompare(b.nombre));

                    setDepartamentos(departamentosArray)
                } else {
                    // Si no hay departamentos, establece el estado como un array vacío
                    setDepartamentos([]);
                }
                if (result.roles) {
                    // Convertir el objeto en un array de pares clave-valor
                    // Convertir el objeto en un array de objetos
                    const rolesArray = Object.entries(result.roles).map(([clave, nombre]) => ({ clave, nombre }));

                    // Ordenar el array de roles en función del segundo campo (nombre)
                    rolesArray.sort((a, b) => a.nombre.localeCompare(b.nombre));

                    setRoles(rolesArray)
                } else {
                    // Si no hay roles, establece el estado como un array vacío
                    setRoles([]);
                }
            })
            .catch(() => {
                mostrarError("No se han podido descargar los datos para poblar el formulario.");
            })

    }

    useEffect(() => {

        getMontaSelect();

    }, [])



    return (
        <div className='container'>
            <div className='content'>
                <div className='article'>
                    <div className='header'>
                        <div className='containerAcciones contenidoIzquierda'>
                            <div>
                                <h3>Configuración de usuarios</h3>
                            </div >
                        </div>
                    </div>
                    <div className='grid-container contenedorAjustes'>
                        <div className='datos-container'>
                            <TextField
                                className='full-width'
                                variant="outlined"
                                margin="normal"
                                label="Usuario"
                                name="selectUsuario"
                                select
                                value={usuarioSelect ? usuarioSelect : ""}
                                size="small"
                                onChange={selectUsuario}
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            sx: { maxHeight: { sm: 74 * 8 + 8 } }
                                        }
                                    }
                                }}

                            >
                                <MenuItem value={0}></MenuItem>
                                {usuarios.map(({ clave, nombre }) => (
                                    <MenuItem key={'user_' + clave} value={clave}>
                                        {nombre}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div className='datos-container usuariosflex'>
                            <TextField
                                disabled={usuarioSelect ? false : true}
                                className='usuariosMedio'
                                variant="outlined"
                                margin="normal"
                                label="Departamento"
                                name="selectDepartamento"
                                select
                                value={departamentoSelect ? departamentoSelect : ""}
                                size="small"
                                onChange={selectDepartamento}
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            sx: { maxHeight: { sm: 36 * 8 + 8 } }
                                        }
                                    }
                                }}

                            >
                                <MenuItem value={0}></MenuItem>
                                {departamentos.map(({ clave, nombre }) => (
                                    <MenuItem key={'dep_' + clave} value={clave}>
                                        {nombre}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                disabled={usuarioSelect ? false : true}
                                className='usuariosMedio'
                                variant="outlined"
                                margin="normal"
                                label="Rol"
                                name="selectRol"
                                select
                                value={rolSelect ? rolSelect : ""}
                                size="small"
                                onChange={selectRol}
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            sx: { maxHeight: { sm: 36 * 8 + 8 } }
                                        }
                                    }
                                }}

                            >
                                <MenuItem value={0}></MenuItem>
                                {roles.map(({ clave, nombre }) => (
                                    <MenuItem key={'rol_' + clave} value={clave}>
                                        {nombre}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div className='centrar datos-container estiloDivider'>
                            {usuarioSelect ?
                                <Button className='usuariosMedio estiloDivider permiso' variant="contained" onClick={setUserData} >Graba</Button>
                                : ''}
                        </div>

                    </div>
                </div>
                {usuarioSelect ?
                    <div className='aside' >
                        <div className='header'>
                            <div className='containerAcciones contenidoIzquierda'>
                                <div>
                                    <h3>Hoteles del usuario</h3>
                                </div >
                            </div>
                        </div>
                        <div className='grid-container contenedorAjustes flexWrap'>
                            {hoteles ? Object.values(hoteles).map((hotel, index) => (
                                <div
                                    key={hotel.id}
                                    id={hotel.id}
                                    className={`hotel ${hotel.permiso === '1' ? 'permiso' : 'no-permiso'} ${idsHotelesQuitar.includes(hotel.id) ? 'quitado' : ''} ${idsHotelesPoner.includes(hotel.id) ? 'puesto' : ''}`}
                                    onClick={(ev) => { toggleHotel(ev) }}
                                >
                                    {hotel.nombre}
                                </div>
                            )) : ''}
                        </div>
                    </div>
                    : ''}
            </div>

            <MostrarError mensajeEr={mensajeError} tipoError={tipoError} contadorError={contadorError} estadoAlert={estadoAlert} />
        </div>
    )
}
export default Usuarios;

