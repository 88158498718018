import React, { useState, useEffect } from 'react';

import { ButtonGroup, ToggleButtonGroup, ToggleButton, Badge, Tooltip, Zoom, Typography, MenuItem, tooltipClasses, styled, Button, TextField, Snackbar, Alert, ListItemText } from '@mui/material';

import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import CoronavirusOutlinedIcon from '@mui/icons-material/CoronavirusOutlined';

import AccionesPisos from '../../components/AccionesPisos/AccionesPisos';
import CargaSpinner from '../../components/AccionesPartes/CargaSpinner';
import './Pisos.css';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(10),
    border: '1px solid #dadde9',

  },
}));

function Pisos() {

  //Funcion utilizada para obtener campos de AccionesPisos el hijo desde Pisos el componente Padre.
  const accionesPisosAPisos = (datos) => {
    if (datos === true) {
      actualizar();//Forzamos la actualizacion.
    }
  }
  let hoteles = localStorage.getItem('hoteles');
  const [hotelesLista, SetHotelesLista] = useState([]);
  const [hotelTrabajo, SetHotelTrabajo] = useState(0);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [mensaje, setMensaje] = React.useState('');
  const [severidad, setSeveridad] = React.useState('success');
  const [habitaciones, setHabitaciones] = React.useState([]);

  const [estadoInicial, setEstadoInicial] = React.useState(true);


  //Info para el Dialog de Pisos Hab.
  const [idHAB, setIdHAB] = React.useState('');
  const [fechaId, setFechaId] = React.useState('');
  const [limpiaHab, setLimpiaHab] = React.useState(false);
  const [bloqueadaHab, setBloqueadaHab] = React.useState(false);
  const [bloqueadaHabNombre, setBloqueadaHabNombre] = React.useState(false);


  const [mapeoHabitaciones, setMapeoHabitaciones] = React.useState('');
  const [actualizo, SetActualizo] = useState(true);

  //Boolean para mostrar o ocultar el spinner de carga
  const [contadorSpinner, setContadorSpinner] = React.useState(false);

  const [toggle, setToggle] = React.useState('estado');


  const mostrarError = (mensajeError) => {
    setMensaje(" " + mensajeError);
    setSeveridad("error");
    setOpenAlert(true);
  };
  const ocultarAlerta = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };
  const handleToggle = (event, newToggle) => {
    if (newToggle !== null) {
      setToggle(newToggle);
      setMapeoHabitaciones([])
      actualizar()
    }
  };
  const mostrarHabitacion = (event) => {
    //Ruta para saber si el boton contiene la clase Limpia. Esto indica si la habitacion esta en true o false.
    //console.log(event.target.parentElement.classList.contains('limpia'))
    setLimpiaHab(event.target.parentElement.classList.contains('limpia'));
    setBloqueadaHab(event.target.parentElement.classList.contains('bloqueada'));
    setBloqueadaHabNombre(event.target.dataset.nombre);

    const hoy = new Date(Date.now());
    setFechaId(hoy.toUTCString());
    setIdHAB(event.target.innerText);
  };
  const mapearHabitaciones = (result) => {
    let estructura = [];
    let estructura2 = [];
    let hab = [];
    let hab2 = [];

    let habitaciones = result;
    setMapeoHabitaciones('');

    if (Object.keys(habitaciones).length === 0) {

      estructura.push(
        <div className='noEncontrado'>{"No se ha podido encontrar Información."}
          <ErrorOutlineIcon className='icon-button-Editar ' />
        </div>
      );
      setMapeoHabitaciones(estructura);
      //SetActualizo(true);

    } else {
      let contador = 1;
      Object.keys(habitaciones).map((idBloque) => {
        //Si contador es impar
        if ((contador % 2) !== 0) {
          estructura.push(
            <div><h3 className='tituloBloque'>{habitaciones[idBloque].bloque}</h3></div>
          );
        } else {
          estructura2.push(
            <div><h3 className='tituloBloque'>{habitaciones[idBloque].bloque}</h3></div>
          );
        }

        let bloque = habitaciones[idBloque]
        Object.keys(bloque).map((idPlanta) => {
          if (idPlanta !== "bloque" && idPlanta !== "id_bloque") {
            hab = [];
            hab2 = [];

            let planta = bloque[idPlanta]
            Object.keys(planta).map((idHab) => {
              let tmp = 'juntar';

              // if (habitaciones[idBloque][idPlanta][idHab]['limpia'] === 1) {
              //   tmp = 'juntar limpia'
              // }
              let estadoHabitacion = habitaciones[idBloque][idPlanta][idHab]['limpia'];
              let bloqueadaHabitacion = habitaciones[idBloque][idPlanta][idHab]['bloqueada'];
              let usuarioBloqueadorHabitacion = habitaciones[idBloque][idPlanta][idHab]['nombre'];
              switch (estadoHabitacion) {
                case 1:
                  tmp = 'juntar limpia'
                  break;
                case 2:
                  tmp = 'juntar noMolestar'
                  break;
                case 3:
                case 4:
                case 5:
                  // tmp = 'juntar bloqueada'
                  break;
                case 6:
                  tmp = 'juntar colorSalida'
                  break;
                case 7:
                  tmp = 'juntar colorSalidaHoy'
                  break;
              }
              if (bloqueadaHabitacion == 1) {
                tmp = tmp + " bloqueada";
              }
              let npartes = habitaciones[idBloque][idPlanta][idHab]['partes']
              if ((contador % 2) !== 0) {
                if (npartes >= 1) {
                  hab.push(
                    <HtmlTooltip
                      placement="top-start" enterDelay={0} leaveDelay={100}
                      title={
                        <React.Fragment>
                          <Typography color="inherit">{"Partes Abiertos: " + npartes}</Typography>
                        </React.Fragment>
                      }
                    >
                      <Badge badgeContent={npartes} color="warning" sx={{ maxWidth: "max-content" }}>
                        <Button className={tmp} onClick={mostrarHabitacion} >
                          <span data-nombre={usuarioBloqueadorHabitacion} className='hab-texto'>{idHab}</span>
                        </Button>
                      </Badge>
                    </HtmlTooltip>

                  );
                } else {
                  hab.push(
                    <Badge badgeContent={npartes} color="warning" sx={{ maxWidth: "max-content" }}>
                      <Button className={tmp} onClick={mostrarHabitacion} >
                        <span data-nombre={usuarioBloqueadorHabitacion} className='hab-texto'>{idHab}</span>
                      </Button>
                    </Badge>

                  );
                }
              } else {
                if (npartes >= 1) {
                  hab2.push(
                    <HtmlTooltip
                      placement="top-start" enterDelay={0} leaveDelay={100} TransitionComponent={Zoom}
                      title={
                        <React.Fragment>
                          <Typography color="inherit">{"Partes Abiertos: " + npartes}</Typography>
                        </React.Fragment>
                      }
                    >
                      <Badge badgeContent={npartes} color="warning" sx={{ maxWidth: "min-content" }}>
                        <Button className={tmp} onClick={mostrarHabitacion} >
                          <span data-nombre={usuarioBloqueadorHabitacion} className='hab-texto'>{idHab}</span>
                        </Button>
                      </Badge>
                    </HtmlTooltip>
                  );
                } else {

                  hab2.push(
                    <Button className={tmp} onClick={mostrarHabitacion} >
                      <span data-nombre={usuarioBloqueadorHabitacion} className='hab-texto'>{idHab}</span>
                    </Button>
                  );
                }
              }
            })

            if ((contador % 2) !== 0) {
              estructura.push(
                <div className='piso-bloque'>
                  <ListItemText className='planta'
                    key={idBloque.toString() + "_" + idPlanta.toString()}
                    value={idBloque + "_" + idPlanta}
                  >
                    {"P" + idPlanta}
                  </ListItemText>
                  <div className='hab'>

                    {hab}
                  </div>
                </div>
              );
              //                  {"Planta: " + idPlanta}
            } else {
              estructura2.push(
                <div className='piso-bloque'>
                  <ListItemText className='planta'
                    key={idBloque.toString() + "_" + idPlanta.toString()}
                    value={idBloque + "_" + idPlanta}
                  >
                    {"P" + idPlanta}
                  </ListItemText>
                  <div className='hab'>
                    {hab2}
                  </div>
                </div>
              );
            }
          }
        })
        if (estructura2) {
          setMapeoHabitaciones(<div className='pisosContainer'><div className='pisosLeft'>{estructura}</div> <div className='pisosLeft'>{estructura2}</div></div>)
          //setMapeoHabitaciones(<div className='pisosContainer'><div className='pisosLeft'>{estructura}</div> <div className='pisosLeft'>{estructura2}</div></div>)
        } else {
          setMapeoHabitaciones(<div className='pisosLeft'>{estructura}</div>);
        }
        contador++;
      })
    }
  }
  const mapearHabitacionesPurgadas = (result) => {
    let estructura = [];
    let estructura2 = [];
    let hab = [];
    let hab2 = [];

    let habitaciones = result;
    setMapeoHabitaciones('');

    if (Object.keys(habitaciones).length === 0) {

      estructura.push(
        <div className='noEncontrado'>{"No se ha podido encontrar Información."}
          <ErrorOutlineIcon className='icon-button-Editar ' />
        </div>
      );
      setMapeoHabitaciones(estructura);
      //SetActualizo(true);

    } else {
      let contador = 1;
      Object.keys(habitaciones).map((idBloque) => {
        //Si contador es impar
        if ((contador % 2) !== 0) {
          estructura.push(
            <div><h3 className='tituloBloque'>{habitaciones[idBloque].bloque}</h3></div>
          );
        } else {
          estructura2.push(
            <div><h3 className='tituloBloque'>{habitaciones[idBloque].bloque}</h3></div>
          );
        }

        let bloque = habitaciones[idBloque]
        Object.keys(bloque).map((idPlanta) => {
          if (idPlanta !== "bloque" && idPlanta !== "id_bloque") {
            hab = [];
            hab2 = [];

            let planta = bloque[idPlanta]
            Object.keys(planta).map((idHab) => {
              let tmp = 'juntar';

              // if (habitaciones[idBloque][idPlanta][idHab]['limpia'] === 1) {
              //   tmp = 'juntar limpia'
              // }
              let bloqueadaHabitacion = habitaciones[idBloque][idPlanta][idHab]['bloqueada'];
              let purgadaHabitacion = habitaciones[idBloque][idPlanta][idHab]['purgada'];
              let usuarioBloqueadorHabitacion = habitaciones[idBloque][idPlanta][idHab]['nombre'];
              if (bloqueadaHabitacion == 1) {
                tmp = tmp + " bloqueada";
              }
              switch (purgadaHabitacion) {
                case 1:
                  tmp = tmp + " sin-purgar";
                  break;

                case 2:
                  tmp = tmp + " purgada";

                  break;

                default:
                  break;
              }
              let npartes = habitaciones[idBloque][idPlanta][idHab]['partes']
              if ((contador % 2) !== 0) {
                if (npartes >= 1) {
                  hab.push(
                    <HtmlTooltip
                      placement="top-start" enterDelay={0} leaveDelay={100}
                      title={
                        <React.Fragment>
                          <Typography color="inherit">{"Partes Abiertos: " + npartes}</Typography>
                        </React.Fragment>
                      }
                    >
                      <Badge badgeContent={npartes} color="warning" sx={{ maxWidth: "max-content" }}>
                        <Button className={tmp} onClick={mostrarHabitacion} >
                          <span data-nombre={usuarioBloqueadorHabitacion} className='hab-texto'>{idHab}</span>
                        </Button>
                      </Badge>
                    </HtmlTooltip>

                  );
                } else {
                  hab.push(
                    <Badge badgeContent={npartes} color="warning" sx={{ maxWidth: "max-content" }}>
                      <Button className={tmp} onClick={mostrarHabitacion} >
                        <span data-nombre={usuarioBloqueadorHabitacion} className='hab-texto'>{idHab}</span>
                      </Button>
                    </Badge>

                  );
                }
              } else {
                if (npartes >= 1) {
                  hab2.push(
                    <HtmlTooltip
                      placement="top-start" enterDelay={0} leaveDelay={100} TransitionComponent={Zoom}
                      title={
                        <React.Fragment>
                          <Typography color="inherit">{"Partes Abiertos: " + npartes}</Typography>
                        </React.Fragment>
                      }
                    >
                      <Badge badgeContent={npartes} color="warning" sx={{ maxWidth: "min-content" }}>
                        <Button className={tmp} onClick={mostrarHabitacion} >
                          <span data-nombre={usuarioBloqueadorHabitacion} className='hab-texto'>{idHab}</span>
                        </Button>
                      </Badge>
                    </HtmlTooltip>
                  );
                } else {

                  hab2.push(
                    <Button className={tmp} onClick={mostrarHabitacion} >
                      <span data-nombre={usuarioBloqueadorHabitacion} className='hab-texto'>{idHab}</span>
                    </Button>
                  );
                }
              }
            })

            if ((contador % 2) !== 0) {
              estructura.push(
                <div className='piso-bloque'>
                  <ListItemText className='planta'
                    key={idBloque.toString() + "_" + idPlanta.toString()}
                    value={idBloque + "_" + idPlanta}
                  >
                    {"P" + idPlanta}
                  </ListItemText>
                  <div className='hab'>

                    {hab}
                  </div>
                </div>
              );
              //                  {"Planta: " + idPlanta}
            } else {
              estructura2.push(
                <div className='piso-bloque'>
                  <ListItemText className='planta'
                    key={idBloque.toString() + "_" + idPlanta.toString()}
                    value={idBloque + "_" + idPlanta}
                  >
                    {"P" + idPlanta}
                  </ListItemText>
                  <div className='hab'>
                    {hab2}
                  </div>
                </div>
              );
            }
          }
        })
        if (estructura2) {
          setMapeoHabitaciones(<div className='pisosContainer'><div className='pisosLeft'>{estructura}</div> <div className='pisosLeft'>{estructura2}</div></div>)
          //setMapeoHabitaciones(<div className='pisosContainer'><div className='pisosLeft'>{estructura}</div> <div className='pisosLeft'>{estructura2}</div></div>)
        } else {
          setMapeoHabitaciones(<div className='pisosLeft'>{estructura}</div>);
        }
        contador++;
      })
    }
  }
  const cambiaHotelTrabajo = (event) => {
    if (event.target.name === 'id_hotel') {
      SetHotelTrabajo(event.target.value);
    }
  }
  const actualizar = () => {
    SetActualizo(!actualizo);
  }
  const fd = new FormData();
  const requestOptions = {
    method: 'POST',
    body: fd
  };

  useEffect(() => {


    // hoteles.unshift({"value":"0","label":""});
    let listaHoteles = JSON.parse(hoteles);
    //listaHoteles.unshift({ "value": "", "label": "" });
    SetHotelesLista(listaHoteles);

    //Si tenemos un hotel guardado en el localStorage utilizando Ajustes.
    let idHotel = localStorage.getItem('idHotelSeleccionado');
    if (estadoInicial) {
      if (idHotel) {
        SetHotelTrabajo(idHotel);
        setEstadoInicial(false);
      } else {
        console.log(listaHoteles);
      }
    }

    if (hotelTrabajo !== 0) {
      //setDepartamentosLista('');
      if (toggle === 'estado') {

        fd.append("f", "habitaciones.getRoomsHotel");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id_hotel", hotelTrabajo);
        fetch('https://api.workers.grupotel.com/', requestOptions)
          .then(response => response.json(), setContadorSpinner(true))
          .then(result => {
            let error = result.error;
            if (error.id === 0) {
              //console.log(result);
              delete result.error;

              const timer2 = setTimeout(() => {
                setContadorSpinner(false)
                setHabitaciones(result)
                mapearHabitaciones(result);
              }, 500);

            } else if (error.id >= 501 && error.id <= 510) {
              localStorage.setItem('token', 'CADUCADO');
              window.location.reload();
            } else {
              mostrarError(error.msg)
            }
          })
          .catch(() => {
            mostrarError("No se ha podido obtener las habitaciones.")
          })
      } else if (toggle === 'ocupacion') {
        fd.append("f", "habitaciones.getPurguedRoomsHotel");
        fd.append("token", localStorage.getItem('token'));
        fd.append("id_hotel", hotelTrabajo);
        fetch('https://api.workers.grupotel.com/', requestOptions)
          .then(response => response.json(), setContadorSpinner(true))
          .then(result => {
            let error = result.error;
            if (error.id === 0) {
              //console.log(result);
              delete result.error;

              const timer2 = setTimeout(() => {
                setContadorSpinner(false)
                setHabitaciones(result)
                mapearHabitacionesPurgadas(result);
              }, 500);

            } else {
              mostrarError(error.msg)
            }
          })
          .catch(() => {
            mostrarError("No se ha podido obtener las habitaciones.")
          })

      }
    } else {
      let estructura = [];
      estructura.push(
        <div className='noEncontrado'>{"No hay ningun hotel seleccionado."}
          <ErrorOutlineIcon className='icon-button-Editar ' />
        </div>
      );
      setMapeoHabitaciones(estructura);
    }
    const interval = setInterval(() => {
      actualizar();
    }, 5 * 60 * 1000); // 5 minutos * 60 segundos/minuto * 1000 milisegundos/segundo

    // Limpieza del intervalo al desmontar el componente
    return () => clearInterval(interval);
  }, [hotelTrabajo, actualizo]);
  return (
    <>
      <div className='container'>
        <div className='content' style={{ "height": "70px" }}>
          <div className='article'>
            <div className='header'>
              <div className='containerAcciones contenidoIzquierda'>

                <ButtonGroup className="botonera" variant="contained" aria-label="outlined button group">
                  <HtmlTooltip
                    placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{"Actualizar"}</Typography>
                      </React.Fragment>
                    }
                  >
                    <Button
                      className="boton"
                      onClick={actualizar}
                    >
                      <RotateLeftIcon className='icon-button' />
                    </Button>
                  </HtmlTooltip>
                  <HtmlTooltip
                    placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        {toggle === 'estado' ?
                          <div className='ayuda-container'>
                            <div className='color-container'>
                              <div className='color pendiente'></div>
                              <h4>Pendiente</h4>
                            </div>
                            <div className='color-container'>
                              <div className='color limpia'></div>
                              <h4>Limpia</h4>
                            </div>
                            <div className='color-container'>
                              <div className='color noMolestar'></div>
                              <h4>No molestar</h4>
                            </div>
                            <div className='color-container'>
                              <div className='color bloqueada'></div>
                              <h4>Bloqueada</h4>
                            </div>
                            <div className='color-container'>
                              <div className='color colorSalida'></div>
                              <h4>Salida</h4>
                            </div>
                            <div className='color-container'>
                              <div className='color colorSalidaHoy'></div>
                              <h4>Sale hoy</h4>
                            </div>

                          </div>
                          :
                          <div className='ayuda-container'>
                            <div className='color-container'>
                              <div className='color pendiente'></div>
                              <h4>Sin necesidad</h4>
                            </div>
                            <div className='color-container'>
                              <div className='color limpia'></div>
                              <h4>Purgada</h4>
                            </div>
                            <div className='color-container'>
                              <div className='color colorSalida'></div>
                              <h4>Sin purgar</h4>
                            </div>

                          </div>
                        }

                      </React.Fragment>
                    }
                  >
                    <Button
                      className="boton"
                    >
                      <InfoOutlinedIcon className='icon-button' />
                    </Button>
                  </HtmlTooltip>
                  <div className='separador'></div>
                  <ToggleButtonGroup
                    className="botonera" aria-label="outlined button group"
                    value={toggle}
                    exclusive
                    onChange={handleToggle}
                  >
                    <HtmlTooltip
                      placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                      title={
                        <>
                          <h4>Mapa de habitaciones</h4>
                        </>
                      }
                    >

                      <ToggleButton className={toggle === 'estado' ? 'boton pintado' : 'boton'} size='small' value="estado" aria-label="Estado">
                        <PersonPinCircleOutlinedIcon className='icon-button'></PersonPinCircleOutlinedIcon>
                      </ToggleButton>
                    </HtmlTooltip>
                    <HtmlTooltip
                      placement="bottom" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom}
                      title={
                        <>
                          <h4>Legionella / Purgaciones</h4>
                        </>
                      }
                    >

                      <ToggleButton className={toggle === 'ocupacion' ? 'boton pintado' : 'boton'} size='small' value="ocupacion" aria-label="Ocupacion">
                        <CoronavirusOutlinedIcon className='icon-button'></CoronavirusOutlinedIcon>
                      </ToggleButton>
                    </HtmlTooltip>
                  </ToggleButtonGroup>
                </ButtonGroup>


              </div>
            </div>
          </div>
          <div className='aside' >
            <div className='header'>
              <TextField
                className='fifty'
                variant="outlined"
                margin="normal"
                label="Hotel"
                name="id_hotel"
                size="small"

                value={hotelTrabajo ? hotelTrabajo : ""}
                onChange={cambiaHotelTrabajo}
                select
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: { maxHeight: { sm: 36 * 8 + 8 } }
                    }
                  }
                }}
              >
                {hotelesLista.map((hotel) => (
                  <MenuItem  key={hotel.value}   value={hotel.value} >
                    {hotel.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>

        </div>
        <div className='full-width'>{mapeoHabitaciones}</div>


        <AccionesPisos idHab={idHAB} idHotel={hotelTrabajo} fechaId={fechaId} limpiaHab={limpiaHab} bloqueadaHab={bloqueadaHab} accionesPisosAPisos={accionesPisosAPisos} bloqueadaHabNombre={bloqueadaHabNombre} />
        <CargaSpinner contadorSpinner={contadorSpinner} />
        <Snackbar open={openAlert} autoHideDuration={3000} onClose={ocultarAlerta}>
          <Alert onClose={ocultarAlerta} severity={severidad} sx={{ width: '100%' }}>
            {mensaje}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}
export default Pisos;
